import type { CustomElement } from '@integrabeauty/custom-elements';
import JustValidate from 'just-validate';
import html from './index.html';
import styles from './index.scss';

/**
 * This element is responsible for rendering and submitting the Gladly request form.
 */
class GladlyRequestForm extends HTMLElement implements CustomElement {
  public static reasonOptions: Record<string, string> = {
    i_have_billing_or_payment_related_questions: 'I have billing or payment related questions',
    i_have_general_questions_about_your_products_or_services:
      'I have general questions about your products or services',
    i_need_help_placing_an_order: 'I need help placing an order',
    i_need_help_tracking_my_order: 'I need help tracking my order',
    i_received_an_incorrect_missing_or_damaged_item:
      'I received an Incorrect missing or damaged item',
    i_would_like_to_cancel_my_order: 'I would like to cancel my order',
    i_would_like_to_change_the_items_or_address_in_the_order_i_just_placed:
      'I would like to change the items or address in the order I just placed',
    i_would_like_to_contact_sales_marketing_or_pr: 'I would like to contact Sales Marketing or PR',
    i_would_like_to_return_refund_or_exchange_an_item:
      'I would like to Return Refund or Exchange an item',
    order_delivered_but_i_never_received_it: 'Order delivered but I never received it'
  };

  public static get observedAttributes() {
    return [
      'data-collapsed'
    ];
  }

  readonly dataset!: {
    /**
     * Lambda url for handling form data.
     */
    apiUrl: string;

    /**
     * Request form hidden / visible (boolean).
     */
    collapsed: string;

    /**
     * reCAPTCHA site key.
     */
    recaptchaKey: string;
  };

  public shadowRoot!: ShadowRoot;
  private attachments: {
    file: string;
    name: string;
  }[] = [];

  private recaptchaVerified?: string;
  private validation?: JustValidate;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.renderReasons();
    this.initForm();
    this.listenInputFileChanges();
    this.initRecaptcha().catch(console.warn);
  }

  public attributeChangedCallback(name: string, _oldValue: string, newValue: string) {
    if (name === 'data-collapsed') {
      if (newValue === 'false') {
        this.clearForm();
      }
    }
  }

  private renderReasons() {
    const reasonElement = this.shadowRoot.getElementById('reason');
    for (const [value, title] of Object.entries(GladlyRequestForm.reasonOptions)) {
      const optionElement = document.createElement('option');
      optionElement.setAttribute('value', value);
      optionElement.textContent = title;
      reasonElement.appendChild(optionElement);
    }
  }

  private initForm() {
    const formElement = this.shadowRoot.querySelector('form');

    this.validation = new JustValidate(formElement, {
      errorFieldCssClass: 'error',
      successFieldCssClass: 'success',
      errorLabelCssClass: 'error-label',
      lockForm: true
    });

    this.validation.addField('#email', [
      {
        rule: 'required',
        errorMessage: 'Email is required'
      },
      {
        rule: 'email',
        errorMessage: 'Please enter a valid email address'
      },
      {
        rule: 'maxLength',
        errorMessage: 'Email must be less than 255 characters long',
        value: 254
      }
    ]);

    this.validation.addField('#body', [
      {
        rule: 'required',
        errorMessage: 'Body is required'
      },
      {
        validator: (value: string) => !/^\s+$/.test(value)
      }
    ]);

    this.validation.addField('#reason', [
      {
        rule: 'required',
        errorMessage: 'Reason is required'
      }
    ]);

    this.validation.addField('#attachments', [
      {
        rule: 'files',
        value: {
          files: {
            extensions: ['jpeg', 'jpg', 'png', 'gif'],
            types: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']
          }
        }
      }
    ]);

    this.validation.onSuccess(this.handleSubmit.bind(this));
  }

  private clearForm() {
    const formElement = this.shadowRoot.querySelector('form');
    formElement.reset();

    // TODO: we occasionally see an error where this.validation is undefined, investigate and fix,
    // we should not have to be defensive here, it looks like clearForm is called on attribute
    // change so what is happening is that sometimes this is called when validation has not yet been
    // initialized, so that is a bug
    if (this.validation) {
      this.validation.refresh();
    }

    const elements = formElement.querySelectorAll('.error');
    for (const element of elements) {
      element.classList.remove('error');
    }

    const labels = formElement.querySelectorAll('.error-label');
    for (const label of labels) {
      label.remove();
    }

    this.attachments = [];
    this.clearFilesPreview();

    this.clearMessages();
  }

  private listenInputFileChanges() {
    const fileInput = <HTMLInputElement>this.shadowRoot.getElementById('attachments');
    fileInput.addEventListener('change', this.onFileInputChanged.bind(this));

    const preventDefaults = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const dropArea = this.shadowRoot.querySelector<HTMLElement>('.drop-area');
    dropArea.addEventListener('dragenter', preventDefaults, false);
    dropArea.addEventListener('dragover', preventDefaults, false);
    dropArea.addEventListener('dragleave', preventDefaults, false);
    dropArea.addEventListener('drop', preventDefaults, false);
    dropArea.addEventListener('drop', this.onDropAreaDrop.bind(this), false);
  }

  private onFileInputChanged(event: Event) {
    const element = <HTMLInputElement>event.target;
    if (!this.validateAttachments(element.files)) {
      element.value = '';
      return;
    }

    this.processFiles(element.files).catch(console.error);
  }

  private onDropAreaDrop(event: DragEvent) {
    if (!this.validateAttachments(event.dataTransfer.files)) {
      return;
    }

    this.processFiles(event.dataTransfer.files).catch(console.error);
  }

  private validateAttachments(files: FileList) {
    const errorElement = this.shadowRoot.querySelector('.files-error');
    errorElement.textContent = '';
    const fileContainer = this.shadowRoot.querySelector('.file');
    fileContainer.classList.remove('error');

    if (files.length > 3) {
      errorElement.textContent = 'You can add only 3 attachment files';
      fileContainer.classList.add('error');
      return false;
    }

    if (!Array.from(files).every(isMaxAllowedSize)) {
      errorElement.textContent = 'Attachments larger that 1mb are not allowed';
      fileContainer.classList.add('error');
      return false;
    }

    return true;
  }

  private async processFiles(files: FileList) {
    this.clearFilesPreview();
    this.attachments = [];

    for (const file of files) {
      try {
        const preview = await readFile(file);
        this.attachments.push({
          file: preview,
          name: file.name
        });
      } catch (error) {
        // We are not interested in capturing the failed to read file error because it indicates a
        // problem with the device or browser, not a programming error.

        if (error instanceof Error && error.message === 'Failed to read file') {
          console.log(error);
        } else {
          console.error(error);
        }
      }
    }

    for (const attachment of this.attachments) {
      this.renderPreview(attachment.file, attachment.name);
    }

    const fileInput = <HTMLInputElement>this.shadowRoot.getElementById('attachments');
    fileInput.value = null;
  }

  private async initRecaptcha() {
    if (window.grecaptcha) {
      this.renderRecaptcha();
      return;
    }

    try {
      await new Promise(resolve => {
        window.recaptchaReady = resolve;
        const script = document.createElement('script');
        script.id = 'recaptcha-script';
        script.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaReady&render=explicit';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      });
      this.renderRecaptcha();
    } catch (error) {
      console.warn(error);
    }
  }

  private renderRecaptcha() {
    // recaptcha div should be rendered outside of shadow dom
    const recaptchaElement = document.querySelector<HTMLElement>('[slot="recaptcha"]');
    if (!recaptchaElement) {
      return;
    }

    window.grecaptcha.render(recaptchaElement, {
      sitekey: this.dataset.recaptchaKey,
      callback: this.markRecaptchaAsVerified.bind(this)
    });
  }

  private handleSubmit(event: Event) {
    event.preventDefault();
    this.clearMessages();

    if (!this.recaptchaVerified) {
      this.showMessage('Please verify captcha before sending the form.', 'danger');
      return;
    }

    const emailInput = <HTMLInputElement>this.shadowRoot.getElementById('email');
    const bodyInput = <HTMLInputElement>this.shadowRoot.getElementById('body');
    const reasonInput = <HTMLInputElement>this.shadowRoot.getElementById('reason');

    const email = emailInput.value;
    const body = bodyInput.value;
    const reason = GladlyRequestForm.reasonOptions[reasonInput.value];

    const payload = {
      body,
      email,
      reason,
      rekey: this.recaptchaVerified,
      subject: `Web Form: [${reason}]`,
      attachments: this.attachments
    };

    this.renderLoadingState();

    fetch(this.dataset.apiUrl, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload)
    }).then((result) => {
      if (!result.ok) {
        throw new Error('Something went wrong, please try again.');
      }

      // We do not use response.json() here because it obscures errors.

      return result.text();
    }).then(text => {
      // TODO: we should be casting here to the correct type
      return <Record<string, any>>JSON.parse(text);
    }).then(result => {
      if (result.status) {
        this.renderDisabledState();
        this.showMessage('Thank you your message has been sent.', 'success');
      } else {
        this.renderEnabledState();
        this.showMessage('There was problem validating CAPTCHA.', 'danger');
      }
    }).catch(error => {
      console.error(error);
      this.renderEnabledState();
      this.showMessage('Something went wrong, please try again.', 'danger');
    });
  }

  private markRecaptchaAsVerified(data: string) {
    this.clearMessages();
    this.recaptchaVerified = data;
  }

  private showMessage(message: string, type: 'danger' | 'success') {
    const messageEl = document.createElement('div');
    messageEl.setAttribute('slot', 'alert-element-content');
    messageEl.textContent = message;

    const alertEl = document.createElement('alert-element');
    alertEl.dataset.alertType = type;
    alertEl.appendChild(messageEl);

    const slot = this.shadowRoot.querySelector('[slot="message"]');
    slot.appendChild(alertEl);
  }

  private clearMessages() {
    const messages = this.shadowRoot.querySelectorAll('alert-element');
    for (const message of messages) {
      message.remove();
    }
  }

  private renderLoadingState() {
    this.shadowRoot.querySelector('.submit').classList.add('loading');
    this.shadowRoot.querySelector('.submit span').classList.add('hidden');
    this.shadowRoot.querySelector('loading-element').classList.remove('hidden');
  }

  private renderDisabledState() {
    const button = this.shadowRoot.querySelector<HTMLButtonElement>('.submit');
    button.disabled = true;
    button.classList.remove('loading');
    this.shadowRoot.querySelector('.submit span').classList.remove('hidden');
    this.shadowRoot.querySelector('loading-element').classList.add('hidden');
  }

  private renderEnabledState() {
    const button = this.shadowRoot.querySelector<HTMLButtonElement>('.submit');
    button.disabled = false;
    button.classList.remove('loading');
    this.shadowRoot.querySelector('.submit span').classList.remove('hidden');
    this.shadowRoot.querySelector('loading-element').classList.add('hidden');
  }

  private clearFilesPreview() {
    const containerElement = this.shadowRoot.querySelector('.files-preview');
    containerElement.innerHTML = '';
  }

  private renderPreview(preview: string, filename: string) {
    const containerElement = this.shadowRoot.querySelector('.files-preview');
    const previewElement = document.createElement('div');
    const imageElement = document.createElement('img');
    const titleElement = document.createElement('span');
    const removeButton = document.createElement('button');

    imageElement.src = preview;
    titleElement.textContent = filename;
    removeButton.textContent = 'x';
    removeButton.title = 'Remove';

    previewElement.appendChild(imageElement);
    previewElement.appendChild(titleElement);
    previewElement.appendChild(removeButton);
    containerElement.appendChild(previewElement);

    removeButton.addEventListener('click',
      this.onRemoveAttachmentClick.bind(this, filename, previewElement));
  }

  private onRemoveAttachmentClick(filename: string, element: HTMLDivElement, event: MouseEvent) {
    event.preventDefault();
    this.attachments = this.attachments.filter(file => file.name !== filename);
    element.remove();
  }
}

function isMaxAllowedSize(file: File) {
  return file.size < 1024 * 1024;
}

/**
 * Reads the contents of a file as a string. Throws an error if there was a problem reading the
 * file.
 */
function readFile(file: File) {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = (_event: ProgressEvent) => {
      resolve(<string>fileReader.result);
    };

    fileReader.onerror = (_event: ProgressEvent) => {
      // We do not get a meaningful error message here, so we have to create one.

      reject(new Error('Failed to read file'));
    };
  });
}

declare global {
  interface HTMLElementTagNameMap {
    'gladly-request-form': GladlyRequestForm;
  }
}

if (!customElements.get('gladly-request-form')) {
  customElements.define('gladly-request-form', GladlyRequestForm);
}
