import './gladly-request-form/index.js';
import * as GladlyTypes from '@integrabeauty/gladly-types';
import * as GladlyUtils from '../../lib/gladly.js';

declare global {
  /**
   * Initialized via liquid. Used exclusively by the help center options section js.
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var help_center_options_section: {
    /**
     * The Gladly load helper checks this array to decide whether to load the Gladly script.
     */
    allowed_host_names: string[];

    /**
     * This value is eventually passed to the externally implemented Gladly.init.
     */
    gladly_config: GladlyTypes.SidekickConfig;
  };
}

async function init() {
  // Do not load if Safari and major browser version lower than 11.
  if (getSafariMajorVersion() < 11) {
    return;
  }

  const didLoad = await GladlyUtils.load(help_center_options_section.allowed_host_names, true);
  if (!didLoad) {
    console.debug('Gladly loaded successfully but load returned false');
    // continue. we use Gladly's help topics feature.
  }

  await GladlyUtils.init(help_center_options_section.gladly_config);

  // Gladly is routinely ad blocked partially, so it loads but then its methods fail because other
  // important modules were blocked or failed to load. This error is so routine that we might as
  // well treat an error as equivalent to technical support being unavailable. getAvailability seems
  // to call some internal function, getState, that is not defined when ad blocked.

  let availability: GladlyTypes.Availability;
  try {
    availability = Gladly.getAvailability();
  } catch (error) {
    // Ignore the error and infer that technical support is unavailable
    availability = 'UNAVAILABLE_BUSY';
  }

  // Open chat from custom button if available
  if (availability === 'AVAILABLE') {
    const startChatButton = document.querySelector('#start-chat');
    startChatButton?.addEventListener('click', () => Gladly.show());
  }

  // When chat is unavailable, change the button text and disable. Reorder to end of list on larger
  // screens only

  if (availability !== 'AVAILABLE') {
    onWasOrBecameUnavailable();
  }

  // Setting the availability as a data-attribute on the "start-chat" element, this allows us to
  // change the look of the button via CSS.

  const startChatContainer = document.querySelector<HTMLElement>('#start-chat-container');
  if (startChatContainer) {
    startChatContainer.dataset.chatAvailable = availability.toLowerCase();
  }

  const emailRequestButton = document.querySelector<HTMLButtonElement>('#email-request-button');
  emailRequestButton?.addEventListener('click', onEmailRequestButtonClicked);

  // This uses the event fired by gladly init instead of directly using the Gladly instance as we
  // know that the Gladly instance is full of nuanced issues that the utility library we implemented
  // abstracts away.
  addEventListener('gladly-availability-changed', onAvailabilityChange);

  const generatedForm = document.querySelector('gladly-request-form');
  const params = new URLSearchParams(location.search);
  if (params.has('show-contact-form') && generatedForm) {
    generatedForm.dataset.collapsed = 'false';
    generatedForm.scrollIntoView({ block: 'nearest' });
    emailRequestButton.textContent = 'Close the request form';
  }
}

function onAvailabilityChange(event: WindowEventMap['gladly-availability-changed']) {
  const startChatContainer = document.querySelector<HTMLElement>('#start-chat-container');
  if (startChatContainer) {
    startChatContainer.dataset.chatAvailable = event.detail.availability.toLowerCase();
  }

  if (event.detail.availability !== 'AVAILABLE') {
    onWasOrBecameUnavailable();
  }
}

function onWasOrBecameUnavailable() {
  const startChatButton = document.querySelector<HTMLElement>('#start-chat');
  if (startChatButton) {
    startChatButton.textContent = 'Our agents are not available right now.';
    startChatButton.setAttribute('disabled', 'disabled');
  }

  // Only reorder if larger screens due to form placement

  const large = matchMedia('(min-width: 769px)');
  if (large.matches) {
    const startChatContainer = document.querySelector('#start-chat-container');
    const helpOptions = document.querySelector('#help-options');
    helpOptions.appendChild(startChatContainer);
  }
}

function onEmailRequestButtonClicked(this: HTMLButtonElement, event: MouseEvent) {
  event.preventDefault();

  const form = document.querySelector('gladly-request-form');
  if (!form) {
    return;
  }

  const button = <HTMLButtonElement>event.currentTarget;

  // Hide the form displaying provided the Gladly classes aren't present
  if (form.dataset.collapsed === 'true') {
    form.dataset.collapsed = 'false';
    form.scrollIntoView({ block: 'nearest' });
    button.setAttribute('aria-expanded', 'true');
    this.textContent = 'Close the request form';
  } else {
    form.dataset.collapsed = 'true';
    button.setAttribute('aria-expanded', 'false');
    this.textContent = 'Submit an email request';
  }
}

function getSafariMajorVersion() {
  const agent = navigator.userAgent;
  const offsetBrowser = agent.indexOf('Safari');

  if (offsetBrowser !== -1) {
    const offsetVersion = agent.indexOf('Version');
    if (offsetVersion !== -1) {
      const [version] = agent.substring(offsetVersion + 8, offsetBrowser)?.split(' ');
      return parseInt(version, 10);
    }
  }
}

function onDOMContentLoaded(_event: Event) {
  // About the only reason we get an error is the particular error that Gladly is undefined during
  // init, which is thrown by the init call. There is nothing we can do about this error. Its
  // precise cause is unclear. It seems like it might be ad block related. It also seems to happen
  // when the site is loaded in a strange environment, e.g. on a proxy, or something that is causing
  // several of the third party fetch calls to fail. Sentry is configured to capture all warning
  // level messages, so we end up with a ton of spam. Therefore, we check for this particular
  // message and log it at a lower level.
  //
  // If the Gladly init function error message changes, we will need to update this code.

  init().catch(error => {
    if (error instanceof Error && error.message === 'Gladly is undefined during init') {
      console.log(error);
    } else {
      console.warn(error);
    }
  });
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
